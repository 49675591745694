const englishMessages = {
    en: {
        translation:{
            menu: {
                habilidades : 'Skills',
                projetos: 'Projects',
                contato: 'Contact',
            },
            capa: {
                subtitulo1: 'FULL STACK',
                subtitulo2: 'DEVELOPER'
            },
            chamada: '"Each code is like a sculpture that must be polished to its best shape"',
            diferenciais:{
                dif1: "Knowledge and experience in creating responsive designs for better web usability",
                dif2: "Use of good practices in building applications and systems, facilitating maintenance and review",
                dif3: "Knowledge of agile work techniques and methodologies for work optimization",
                dif4: "Ability to create original designs with a focus on the best user experience",
            },
            habilidades:{
                outras : "Others"
            },
            projetos: {
                1:{
                    titulo: 'Clicker Game',
                    cargo: 'Full Development',
                    tecnologias: ['React'],
                    descricao: 'Project developed as a study of the React library and consists of a clicker game with several heroes and resources to be collected as well as improvements to be acquired. The entire structure of heroes, resources and improvements can be dynamically changed via the implementation of a database, in this case, for study purposes only a local .js file was used simulating a database'     
                },
                2:{
                    titulo: 'Infocorp Website',
                    cargo: 'Back-End Development',
                    tecnologias: ['Java Spring Boot','PostgreSQL'],
                    descricao: "Project developed with the junior company InfoCorp for the company's website, my role was to build an API with the team for storing projects, sending contact forms and implementing security on endpoints with Spring Security. Additionally, the entire API database was built with PostgreSQL"     
                }
            },
            contato:{
                titulo: "Send a Message!",
                placeholder: {
                    nome:"Your name",
                    email: "Your e-mail",
                    mensagem: "Your message"
                },
                botao: "Send!",
                sucessoCard: "Success! Your message was sent successfully",
                erroCard: "There was an error trying to send your messagem. Please, try again",
            },
            footer:{
                direitos: "All rights reserved"
            },
            fundo:{
                habilidades:"SKILLS",
                contato:"CONTACT"
            }
        }
    }
}

export {englishMessages};