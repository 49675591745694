const portugueseMessages = {
    pt: {
        translation:{
            menu: {
                habilidades : 'Habilidades',
                projetos: 'Projetos',
                contato: 'Contato',
            },
            capa: {
                subtitulo1: 'DESENVOLVEDOR',
                subtitulo2: 'FULL STACK'
            },
            chamada: '"Cada código é como uma escultura que deve ser lapidada à sua melhor forma"',
            diferenciais:{
                dif1: "Conhecimentos e experiência na criação de designs responsivos para melhor usabilidade web",
                dif2: "Uso de Boas práticas na construção de aplicações e sistemas facilitando manutenção e revisão",
                dif3: "Conhecimentos sobre técnicas e metodologias ágeis de trabalho para otimização de trabalho",
                dif4: "Capacidade de criação de designs próprios com foco na melhor experiência de usuário",
            },
            habilidades:{
                outras : "Outras"
            },
            projetos: {
                1: {
                    titulo: 'Jogo Clicker',
                    cargo: 'Desenvolvimento Completo',
                    tecnologias: ['React'],
                    descricao: 'Projeto desenvolvido como estudo da biblioteca React e consiste em um jogo clicker com diversos heróis e recursos para serem coletados além de melhorias para se adquirir. Toda a estrutura de hérois, recursos e melhorias pode ser alterada dinâmicamentes via implementação de um banco de dados, neste caso, para fins de estudo foi utilizado apenas um arquivo local .js simulando um banco de dados'
                },
                2: {
                    titulo: 'Site Infocorp',
                    cargo: 'Desenvolvimento Back-End',
                    tecnologias: ['Java Spring Boot','PostgreSQL'],
                    descricao: 'Projeto desenvolvido junto à empresa júnior InfoCorp para o site da empresa, meu papel foi construir junto à equipe uma API para armazenamento dos projetos, envio de formulários de contato e implementar a segurança nos endpoints com o Spring Security. Além disso, todo o banco de dados da API foi feito com PostgreSQL'
                }
            },
            contato:{
                titulo: "Fale Comigo!",
                placeholder: {
                    nome:"Seu nome",
                    email: "Seu e-mail",
                    mensagem: "Sua mensagem"
                },
                botao: "Enviar!",
                sucessoCard:"Sucesso! O formulário foi enviado com sucesso",
                erroCard:"Erro ao enviar o formulário. Por favor, tente novamente"
            },
            footer:{
                direitos: "Todos os direitos reservados",
            },
            fundo:{
                habilidades:"HABILIDADES",
                contato:"CONTATO"
            }
        }
    }
}
export {portugueseMessages};