import { useTranslation } from "react-i18next";

function Footer(){

    const {t} = useTranslation();

    return(
        <div className="bg-bg_secondary w-full py-6 text-center font-light leading-6 text-sm">
            <p>Juancassemiro1@gmail.com</p>
            <p>(65) 99900-4321</p>
            <p>Cuiabá, MT - Brasil</p>
            <p className="pt-2">{t('footer.direitos')}</p>

        </div>
    )
}

export default Footer;
