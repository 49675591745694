import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import DiferenciaisContainer from "../DiferenciaisContainer/DiferenciaisContainer";
import HabilidadeContainer from "../HabilidadeContainer/HabilidadeContainer";

function Habilidades(){
    const habilidadesFront = ['Javascript','Typescript','React','Next.js','TailwindCSS'];
    const habilidadesBack = ['Java Spring Boot','Node.js','PostgreSQL','MySQL','MongoDB','API RESTFul'];
    const habilidadesOutras = ['Figma','Photoshop'];
    const {t} = useTranslation();

    return(
        <div className="bg-bg_primary pt-20 relative" id="habilidades">
        <Container>
            <div className="flex flex-row flex-wrap justify-evenly pb-60">
            
                <DiferenciaisContainer icone="dashboard"
                texto={t('diferenciais.dif1')}/>
                <DiferenciaisContainer icone="code" 
                texto={t('diferenciais.dif2')}/>
                <DiferenciaisContainer icone="tactic" 
                texto={t('diferenciais.dif3')}/>
                <DiferenciaisContainer icone="design_services"
                texto={t('diferenciais.dif4')}/>
            </div>
            <div className="flex flex-row justify-evenly flex-wrap">
                <HabilidadeContainer icone="web" titulo="Front-End" lista={habilidadesFront}/>
                <HabilidadeContainer icone="database" titulo="Back-End" lista={habilidadesBack}/>
                <HabilidadeContainer icone="construction" titulo={t("habilidades.outras")} lista={habilidadesOutras}/>
            </div>
        </Container>
        <span className="lg:text-fundo1 sm:text-fundo2 font-black absolute lg:top-1/3 sm:top-1/2 right-4 select-none">{t("fundo.habilidades")}</span>
        </div>
    )
}

export default Habilidades;