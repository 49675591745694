import { useTranslation } from "react-i18next";


function NomeCapa(){
    
    const {t} = useTranslation();

    return (
        <div className="lg:w-1/2 sm:w-3/4 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 select-none">
            <h3 className="transition-all xl:text-nome lg:text-6xl md:text-5xl sm:text-4xl mt-10 leading-none font-black">JUAN<br/>CASSEMIRO</h3>
            <span className="w-full bg-white h-px block"></span>
            <h3 className="transition-all xl:text-nome lg:text-6xl md:text-5xl sm:text-4xl m-auto text-right leading-none font-black">{t('capa.subtitulo1')}<br/>{t('capa.subtitulo2')}</h3>
        </div>
    )
}

export default NomeCapa;